<template>
  <Dialog
    v-model="useDialogStore().userEdit.isOpen"
    size="small-hug"
    @open="onOpen"
  >
    <template #header="{ close }">
      <DialogHeader
        :title="useT('bcd231')"
        :show-close="true"
        @close="close"
      />
    </template>

    <Form
      ref="formRef"
      :model="form"
      :rules="rules"
      @submit="onSubmit"
    >
      <InputBlock
        :label="useT('bcd9')"
        prop="fullName"
      >
        <TextInput
          v-model="form.fullName"
          name="fullName"
        />
      </InputBlock>

      <InputBlock
        :label="useT('bcd206')"
        prop="email"
      >
        <TextInput
          v-model="form.email"
          type="email"
          name="email"
          :placeholder="useT('bcd206')"
        />
      </InputBlock>

      <InputBlock
        :label="useT('bcd307')"
        prop="role"
      >
        <Select
          v-model="form.role"
          name="role"
          :options="useUsersStore().roles.map((role) => ({ id: role, text: _upperFirst(role) }))"
        />
      </InputBlock>

      <InputBlock
        :label="useT('bcd10')"
        prop="password"
      >
        <TextInput
          v-model="form.password"
          type="password"
          name="password"
          autocomplete="new-password"
          data-private
          :placeholder="useT('bcd21')"
        />
        <UserPasswordStrength
          v-if="form.password?.length"
          :password="form.password"
          :rules="rules.password"
        />
      </InputBlock>
    </Form>

    <template
      #footer
    >
      <div class="flex justify-between items-center">
        <Button
          type="regular-text"
          content-icon="trash-01"
          @click="removeUser"
        />
        <ButtonGroup
          type="primary"
          :text-submit="useT('bcd15')"
          :text-cancel="useT('bcd205')"
          align="right"
          :loading="isLoading"
          @submit="formRef.onSubmit()"
          @cancel="useDialogStore().close('userEdit')"
        />
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import { Checkbox } from '#components';

// Refs
const form = ref({
  fullName: '',
  email: '',
  role: '',
  password: '',
});

const rules = computed(() => ({
  email: [
    useFormRules().required,
    useFormRules().email,
  ],
  role: [useFormRules().required],
  password: form.value.password ? useFormRules().password : [],
}));

const isLoading = ref(false);
const formRef = ref(null);
const removeUserFromAllWorkspaces = ref(false);

// Methods
const onOpen = () => {
  form.value = {
    fullName: useDialogStore().userEdit.user.fullName,
    email: useDialogStore().userEdit.user.email,
    role: useDialogStore().userEdit.user.role,
    password: '',
  };
};

const removeUser = () => {
  removeUserFromAllWorkspaces.value = false;

  const renderBodyComponent = (value) => h('div', { class: 'mt-4' }, [
    h(Checkbox, {
      modelValue: value,
      label: useT('bcd319'),
      'onUpdate:modelValue': (value) => {
        removeUserFromAllWorkspaces.value = value;
        useModalStore().modal.bodyComponent = renderBodyComponent(value); // reactive change checkbox value
      },
    }),
  ]);

  useModalStore().open({
    type: 'danger',
    title: useT('bcd85', { value: useDialogStore().userEdit.user?.fullName || useDialogStore().userEdit.user?.email || '' }),
    description: useT('bcd84'),
    submitButtonText: useT('bcd73'),
    cancelButtonText: useT('bcd205'),
    bodyComponent: renderBodyComponent(removeUserFromAllWorkspaces.value),
    onSubmit: async() => {
      await useUsersStore().deleteUser(useDialogStore().userEdit.user, {
        removeUserFromAllWorkspaces: removeUserFromAllWorkspaces.value,
      });
      useDialogStore().close('userEdit');
    },
  });
};

const onSubmit = async() => {
  isLoading.value = true;

  const response = await useUsersStore().editUser(useDialogStore().userEdit.user._id, form.value);

  isLoading.value = false;

  if (response) {
    useToast(useT('bcd45'));
    useDialogStore().close('userEdit');
    useDialogStore().userEdit.onSubmit(response);
  }
};
</script>
